var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top barging-container"},[_c('div',{staticClass:"flex flex-row justify-between p-1 laptop:pb-2 desktop:pb-8"},[_c('tabs',{attrs:{"tabs":_vm.tabsList,"currentIndex":_vm.upIndex},on:{"updateIndex":_vm.updateIndex}})],1),_c('div',{staticClass:"barging-card",staticStyle:{"padding":"0px 0px","background-color":"#f2f7fa","border-radius":"10px"}},[_c('div',{staticClass:"flex flex-row justify-end pr-2 pl-2 mb-2 pt-2"},[_c('div',{staticClass:"flex gap-x-4 text-right",staticStyle:{"width":"400px"}},[_c('el-input',{staticClass:"Comipt",attrs:{"size":"small","placeholder":_vm.$t('btn.search')},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Pendinginputval(_vm.Pendinginput)}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}},[_c('span',{attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.Pendinginputval(_vm.Pendinginput)}},slot:"suffix"},[_c('svg-icon',{attrs:{"iconClass":"search"}})],1)]),_c('el-select',{staticClass:"Pendingselect",attrs:{"size":"small","placeholder":_vm.$t('btn.Today')},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('div',{staticClass:"box-card barging-table",class:{'mobile-view':_vm.isMobileScreen}},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"ReqTable w-full Comtable"},[_c('el-table',{attrs:{"stripe":"","data":_vm.listData,"header-cell-style":{
              height: '55px',
              background: '#fff',
              'border-bottom': '2px solid #D6D9E1',
            },"row-style":{
              height: _vm.screenType.isMobileScreen? '30px':'64px',
            },"cell-style":_vm.getCellStyle,"table-layout":"auto","height":"100%"},on:{"row-click":_vm.handleRowClick}},[_c('el-table-column',{attrs:{"width":"200","align":"left","prop":"requestType","label":"Request Type"}}),_c('el-table-column',{attrs:{"width":"200","align":"left","prop":"createName","label":"Requestor"}}),_c('el-table-column',{attrs:{"width":"180","align":"left","prop":"createTime","label":"Date and Time"}}),_c('el-table-column',{attrs:{"width":"280","align":"left","prop":"reason","label":"Reason"}}),_c('el-table-column',{attrs:{"align":"right","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.upIndex == 0 && _vm.userInfo.role == 1)?_c('el-button',{ref:'popoverRef_' + scope.row.id,staticClass:"acknowledgedButton",attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.Acknowclick(scope.row)}}},[_c('span',{staticClass:"acknowledgedButton"},[_vm._v(" "+_vm._s(_vm.$t("btn.Acknowledged"))+" ")])]):_vm._e(),(_vm.upIndex == 1 && _vm.userInfo.role == 3 && scope.row.applyType == 1)?_c('el-button',{ref:'popoverRef_' + scope.row.id,attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.ResersBtn(scope.row)}}},[_c('span',{staticClass:"resetButton"},[_vm._v(" "+_vm._s(_vm.$t("btn.ReserSignature"))+" ")])]):_vm._e()]}}])})],1)],1)])]),_c('div',{staticClass:"barging-pagination"},[_c('pagination',{attrs:{"total":_vm.total,"page":_vm.pageParams.pageNum,"limit":_vm.pageParams.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.pageParams, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.pageParams, "pageSize", $event)},"pagination":_vm.getlist}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }